import React from "react";
import { EnvelopeRegular } from "../../components/EnvelopeRegular";
import { LocationDotSolid2 } from "../../icons/LocationDotSolid2";
import { LocationDotSolid21 } from "../../icons/LocationDotSolid21";
import { PhoneSolid2 } from "../../icons/PhoneSolid2";
import { PhoneSolid21 } from "../../icons/PhoneSolid21";
import "./style.css";

export const Desktop = () => {
  return (
    <div className="desktop">
      <div className="div">
        <div className="top-bar">
          <div className="rectangle" />
          <div className="top-bar-2">
            <EnvelopeRegular
              className="envelope-regular-1"
              iClassFaSolidFaClassName="envelope-regular-instance"
              vector="/img/vector-1.svg"
              vectorClassName="envelope-regular-1-instance"
            />
            <PhoneSolid2 className="phone-solid" />
            <LocationDotSolid2 className="location-dot-solid" />
          </div>
          <p className="honig-aus-wesensgem">
            <span className="text-wrapper">
              Honig aus wesensgemäßer Bienenhaltung aus dem Österreichischen Waldviertel
            </span>
            <span className="span">&nbsp;</span>
          </p>
        </div>
        <div className="top-bar-3">
          <div className="rectangle" />
          <div className="text-wrapper-2">Impressum</div>
        </div>
        <img className="frame" alt="Frame" src="/img/frame-30.png" />
        <div className="overlap-group">
          <img className="tehobackground" alt="Tehobackground" src="/img/tehobackground2-1.png" />
          <div className="flexcontainer-wrapper">
            <div className="flexcontainer">
              <p className="text">
                <span className="text-wrapper-3">
                  Theos Honig
                  <br />
                </span>
              </p>
              <p className="span-wrapper">
                <span className="text-wrapper-4">Naturreiner Honig aus Schiltern</span>
              </p>
            </div>
          </div>
          <div className="footer-wrapper">
            <footer className="footer">
              <div className="frame-2">
                <EnvelopeRegular
                  className="envelope-regular-1"
                  iClassFaSolidFaClassName="design-component-instance-node"
                  vector="/img/vector-2.svg"
                  vectorClassName="envelope-regular-1-instance"
                />
                <div className="div-2">honig@teho.at</div>
              </div>
              <div className="frame-3">
                <PhoneSolid21 className="phone-solid" />
                <p className="div-2">
                  <span className="text-wrapper-5">+43 (</span>
                  <a
                    href="https://www.google.com/search?client=firefox-b-d&amp;q=gibler+und+poth#"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span className="text-wrapper-5">0)664 / 4</span>
                  </a>
                  <span className="text-wrapper-5">5 84 280</span>
                </p>
              </div>
              <div className="frame-4">
                <LocationDotSolid21 className="location-dot-solid" />
                <p className="obere-strasse">
                  Obere Strasse 33, <br />
                  3553 Schiltern, Österreich
                </p>
              </div>
            </footer>
          </div>
          <div className="frame-5">
            <img className="blumehonig" alt="Blumehonig" src="/img/blumehonig2-1.png" />
            <div className="frame-6">
              <div className="flexcontainer-2">
                <p className="p">
                  <span className="text-wrapper-6">
                    DieLieblingsblumen der Bienen <br />
                  </span>
                </p>
                <p className="p">
                  <span className="text-wrapper-7">
                    Über die DNA unseres Honigs
                    <br />
                  </span>
                </p>
                <p className="p">
                  <span className="text-wrapper-8">
                    Wir analysieren unseren Honig. So garantieren wir ausge­zeichnete Qualität und ­wissen, welche
                    Pflanzen­ unserem Honig seinen Geschmack verleihen. Entdecke hier aus welchen Pflanzenpollen unser
                    Honig gemacht ist.{" "}
                  </span>
                </p>
              </div>
              <button className="button">
                <div className="KOSTENLOSES">Honig DNA entdecken</div>
                <img className="img" alt="Frame" src="/img/frame-17.svg" />
              </button>
            </div>
          </div>
          <div className="text-wrapper-9">Honig bestellen</div>
        </div>
      </div>
    </div>
  );
};
