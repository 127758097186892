/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const EnvelopeRegular = ({
  className,
  iClassFaSolidFaClassName,
  vectorClassName,
  vector = "/img/vector.png",
}) => {
  return (
    <div className={`envelope-regular ${className}`}>
      <div className={`i-class-fa-solid-fa ${iClassFaSolidFaClassName}`}>
        &lt;i class=&#34;fa-solid fa-bars&#34;&gt;&lt;/i&gt;
      </div>
      <img className={`vector ${vectorClassName}`} alt="Vector" src={vector} />
    </div>
  );
};

EnvelopeRegular.propTypes = {
  vector: PropTypes.string,
};
