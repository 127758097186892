/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PhoneSolid21 = ({ className }) => {
  return (
    <svg
      className={`phone-solid-2-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_124_776)">
        <path
          className="path"
          d="M5.15312 0.768722C4.9125 0.187472 4.27812 -0.121903 3.67187 0.0437222L0.921874 0.793721C0.378125 0.943721 0 1.43747 0 1.99997C0 9.73121 6.26874 16 14 16C14.5625 16 15.0562 15.6218 15.2062 15.0781L15.9562 12.3281C16.1219 11.7218 15.8125 11.0875 15.2312 10.8468L12.2312 9.59684C11.7219 9.38434 11.1312 9.53121 10.7844 9.95934L9.52187 11.5C7.32187 10.4593 5.54062 8.67809 4.5 6.47809L6.04062 5.21872C6.46874 4.86872 6.61562 4.28122 6.40312 3.77184L5.15312 0.771847V0.768722Z"
          fill="#8A3726"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_124_776">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
