/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LocationDotSolid2 = ({ className }) => {
  return (
    <svg
      className={`location-dot-solid-2 ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 12 17"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_45_301)">
        <path
          className="path"
          d="M6.74062 16.3016C8.34374 14.2954 12 9.4329 12 6.70165C12 3.38916 9.31249 0.70166 5.99999 0.70166C2.6875 0.70166 0 3.38916 0 6.70165C0 9.4329 3.65625 14.2954 5.25937 16.3016C5.64374 16.7798 6.35624 16.7798 6.74062 16.3016ZM5.99999 4.70166C6.53043 4.70166 7.03913 4.91237 7.41421 5.28744C7.78928 5.66251 7.99999 6.17122 7.99999 6.70165C7.99999 7.23209 7.78928 7.74079 7.41421 8.11587C7.03913 8.49094 6.53043 8.70165 5.99999 8.70165C5.46956 8.70165 4.96085 8.49094 4.58578 8.11587C4.21071 7.74079 4 7.23209 4 6.70165C4 6.17122 4.21071 5.66251 4.58578 5.28744C4.96085 4.91237 5.46956 4.70166 5.99999 4.70166Z"
          fill="white"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_45_301">
          <rect className="rect" fill="white" height="16" transform="translate(0 0.70166)" width="12" />
        </clipPath>
      </defs>
    </svg>
  );
};
