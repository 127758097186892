/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LocationDotSolid21 = ({ className }) => {
  return (
    <svg
      className={`location-dot-solid-2-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 12 16"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_124_777)">
        <path
          className="path"
          d="M6.74062 15.6C8.34374 13.5937 12 8.73124 12 5.99999C12 2.6875 9.31249 0 5.99999 0C2.6875 0 0 2.6875 0 5.99999C0 8.73124 3.65625 13.5937 5.25937 15.6C5.64374 16.0781 6.35624 16.0781 6.74062 15.6ZM5.99999 4C6.53043 4 7.03913 4.21071 7.41421 4.58578C7.78928 4.96085 7.99999 5.46956 7.99999 5.99999C7.99999 6.53043 7.78928 7.03913 7.41421 7.41421C7.03913 7.78928 6.53043 7.99999 5.99999 7.99999C5.46956 7.99999 4.96085 7.78928 4.58578 7.41421C4.21071 7.03913 4 6.53043 4 5.99999C4 5.46956 4.21071 4.96085 4.58578 4.58578C4.96085 4.21071 5.46956 4 5.99999 4Z"
          fill="#8A3726"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_124_777">
          <rect className="rect" fill="white" height="16" width="12" />
        </clipPath>
      </defs>
    </svg>
  );
};
