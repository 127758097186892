/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PhoneSolid2 = ({ className }) => {
  return (
    <svg
      className={`phone-solid-2 ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_45_303)">
        <path
          className="path"
          d="M5.15312 1.47038C4.9125 0.889132 4.27812 0.579757 3.67187 0.745382L0.921874 1.49538C0.378125 1.64538 0 2.13913 0 2.70163C0 10.4329 6.26874 16.7016 14 16.7016C14.5625 16.7016 15.0562 16.3235 15.2062 15.7797L15.9562 13.0297C16.1219 12.4235 15.8125 11.7891 15.2312 11.5485L12.2312 10.2985C11.7219 10.086 11.1312 10.2329 10.7844 10.661L9.52187 12.2016C7.32187 11.161 5.54062 9.37975 4.5 7.17975L6.04062 5.92038C6.46874 5.57038 6.61562 4.98288 6.40312 4.4735L5.15312 1.47351V1.47038Z"
          fill="white"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_45_303">
          <rect className="rect" fill="white" height="16" transform="translate(0 0.70166)" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
